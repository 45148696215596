import CityMostJobs from "@/interfaces/address/CityMostJobs"
import { JobsQueryParams } from "@/interfaces/job/JobQueryParams"
import useSWR from "swr"

export function useCitiesSearchByJobs({ pageSize, sortBy }: JobsQueryParams) {
  const { data, isLoading, error, mutate } = useSWR(
    `/job/search/cities?groupBy=NAME&orderBy=${sortBy}&pageSize=${pageSize}`,
  )

  return {
    cities: (data?.data ?? []) as CityMostJobs[],
    citiesCount: data?.count ?? 0,
    isLoadingCities: isLoading,
    isErrorCities: error,
    mutateCities: mutate,
  }
}
