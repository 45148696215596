"use client"

import { useCitiesSearchByJobs } from "@/hooks/useCitiesSearchByJobs"
import { usePositionsSearchByJobs } from "@/hooks/usePositionsSearchByJobs"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Image from "next/image"
import * as React from "react"

import ListViewMostPopularJobsSection from "./ListViewMostPopularJobsSection"

const CARD_POSITIONS = {
  title: "Buscas mais populares",
  imageSrc: getAssetsPath() + "/img/icon-elevacao.svg",
}

export default function MostPopularJobsSection() {
  const [value, setValue] = React.useState("1")

  const { cities } = useCitiesSearchByJobs({
    pageSize: 12,
    sortBy: "total",
  })

  const { positions } = usePositionsSearchByJobs({
    pageSize: 12,
    sortBy: "total",
  })

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box>
      {cities.length && positions.length && (
        <Box
          sx={{
            margin: "0px",
            marginBlockEnd: "40px",
            padding: "20px",
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            border: "1px solid",
            borderRadius: "15px",
            borderColor: "#9BC2D8",
          }}
        >
          <Box
            sx={{
              width: "100%",
              typography: "body1",
            }}
          >
            <Image
              src={CARD_POSITIONS.imageSrc}
              alt=""
              width={0}
              height={0}
              style={{ width: "25px", height: "25px" }}
            />
            <span
              style={{
                marginLeft: "10px",
                fontSize: "20px",
                fontWeight: "bold",
                color: "#13509C",
              }}
            >
              {CARD_POSITIONS.title}
            </span>

            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList onChange={handleChange}>
                  <Tab label="Vagas" value="1" />
                  <Tab label="Cidades" value="2" />
                </TabList>
              </Box>

              <TabPanel value="1" sx={{ padding: "0" }}>
                <ListViewMostPopularJobsSection data={positions} />
              </TabPanel>

              <TabPanel value="2" sx={{ padding: "0" }}>
                <ListViewMostPopularJobsSection data={cities} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      )}
    </Box>
  )
}
