import { AlertColor, AlertPropsColorOverrides } from "@mui/material"
import { OverridableStringUnion } from "@mui/types"
import { create } from "zustand"

type AlertSeverity = OverridableStringUnion<AlertColor, AlertPropsColorOverrides>

type Store = {
  isMessageSnackbarOpen: boolean
  message: string | undefined
  severity: AlertSeverity | undefined

  successMessage: (message: string) => void
  errorMessage: (message: string) => void
  infoMessage: (message: string) => void
  warningMessage: (message: string) => void

  closeMessageSnackbar: () => void
}

export const useMessageSnackbarStore = create<Store>((set) => ({
  isMessageSnackbarOpen: false,
  message: undefined,
  severity: undefined,

  successMessage: (message: string) =>
    set(() => ({ isMessageSnackbarOpen: true, message, severity: "success" })),
  errorMessage: (message: string) =>
    set(() => ({ isMessageSnackbarOpen: true, message, severity: "error" })),
  infoMessage: (message: string) =>
    set(() => ({ isMessageSnackbarOpen: true, message, severity: "info" })),
  warningMessage: (message: string) =>
    set(() => ({ isMessageSnackbarOpen: true, message, severity: "warning" })),

  closeMessageSnackbar: () =>
    set(() => ({
      isMessageSnackbarOpen: false,
      message: undefined,
      severity: undefined,
    })),
}))
