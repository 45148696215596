import CityMostJobs from "@/interfaces/address/CityMostJobs"
import JobMostPopular from "@/interfaces/job/JobMostPopular"
import { ListItemButton, ListItemText } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { useRouter } from "next/navigation"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function ListViewMostPopularJobsSection({ data }) {
  const router = useRouter()

  function handleOpenPositions(filter: string) {
    router.push("/busca-vaga?query=" + filter)
  }

  return (
    <>
      <Grid container columns={{ xs: 2, sm: 3, md: 12 }}>
        {data.map((item: CityMostJobs | JobMostPopular) => (
          <Grid xs={2} sm={4} md={3} key={item.id}>
            <ListItemButton
              onClick={() => {
                handleOpenPositions(item.name)
              }}
              sx={{
                ":active": { color: "greenMsGov.main" },
                ":hover": { color: "greenMsGov.main" },
              }}
            >
              {
                <ListItemText
                  primary={
                    item.name.length >= 30
                      ? item.name.substring(0, 27) + "..."
                      : item.name
                  }
                />
              }
            </ListItemButton>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
