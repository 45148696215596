import JobMostPopular from "@/interfaces/job/JobMostPopular"
import { JobsQueryParams } from "@/interfaces/job/JobQueryParams"
import useSWR from "swr"

export function usePositionsSearchByJobs({ pageSize, sortBy }: JobsQueryParams) {
  const { data, isLoading, error, mutate } = useSWR(
    `/job/search/positions?groupBy=NAME&orderBy=${sortBy}&pageSize=${pageSize}`,
  )

  return {
    positions: (data?.data ?? []) as JobMostPopular[],
    positionsCount: data?.count ?? 0,
    isLoadingPositions: isLoading,
    isErrorPositions: error,
    mutatePositions: mutate,
  }
}
