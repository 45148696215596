"use client"

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material"
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemText,
  Stack,
  Theme,
  Typography,
  styled,
} from "@mui/material"
import Link from "next/link"
import { useState } from "react"

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled(function (props: ExpandMoreProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props
  return <IconButton {...other} />
})(function (props: { theme: Theme; expand: boolean }) {
  const { theme, expand } = props
  return {
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }
})

type CardHomeProps = {
  title: string
  imageSrc: string
  imageAlt: string
  accessLinkUrl: string
  additionalInfo: string[]
}

export function HomeCard(props: CardHomeProps) {
  const { title, imageSrc, imageAlt, additionalInfo, accessLinkUrl } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: {
          xs: "25rem",
          md: "100%",
        },
        margin: {
          xs: "auto",
          md: 0,
        },
        p: 3,
      }}
    >
      <CardMedia
        component="img"
        image={imageSrc}
        alt={imageAlt}
        sx={{
          height: {
            xs: 225,
            sm: 250,
            md: 200,
            lg: 225,
          },
          width: {
            xs: 225,
            sm: 250,
            md: 200,
            lg: 225,
          },
          margin: "auto",
          objectFit: "contain",
        }}
      />
      <CardContent sx={{ px: 0, minHeight: "96px" }}>
        <Stack
          direction="row"
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            columnGap: 2,
          }}
        >
          <Typography variant="h6">
            <Link
              scroll={false}
              style={{ textDecoration: "none", color: "inherit" }}
              href={accessLinkUrl}
            >
              {title}
            </Link>
          </Typography>
          {/* 
            <Link
              href={accessLinkUrl}
              sx={{
                display: "flex",
                fontWeight: 700,
                fontSize: "0.8em",
                textDecoration: "none",
                mt: 1,
                textAlign: "center",
              }}
              onClick={() => {}}
            >
              ACESSE AGORA
            </Link>
          */}
        </Stack>
      </CardContent>
      <CardActions sx={{ px: 0 }}>
        <Typography
          onClick={handleExpandClick}
          sx={{
            mr: "auto",
            cursor: "pointer",
            color: "primary.main",
            ":hover": { color: "primary.dark" },
          }}
        >
          Saiba mais
        </Typography>
        <ExpandMore
          expand={isExpanded}
          onClick={handleExpandClick}
          aria-expanded={isExpanded}
          aria-label="saiba mais"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List sx={{ px: 0 }}>
          {additionalInfo.map((info, index) => (
            <div key={index}>
              <ListItem sx={{ px: 0 }}>
                <ListItemText primary={info} />
              </ListItem>
              {index !== additionalInfo.length - 1 && <Divider component="li" />}
            </div>
          ))}
        </List>
      </Collapse>
    </Card>
  )
}
