"use client"

import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Box, Container, Theme, useMediaQuery } from "@mui/material"
import Image from "next/image"

export default function AnnouncementSection() {
  const isScreenSizeSmallerThanMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  )

  const appAnnouncementImageSrc = isScreenSizeSmallerThanMedium
    ? getAssetsPath() + "/img/banner-ms-qualifica-no-aplicativo-mobile.svg"
    : getAssetsPath() + "/img/banner-ms-qualifica-no-aplicativo-desktop.svg"

  return (
    <Box
      component="section"
      sx={{
        background: {
          xs: `linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 75%,
            rgba(214, 234, 223, 1) 25%
          );`,
          md: `linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 50%,
            rgba(214, 234, 223, 1) 50%
          );`,
        },
        pb: 9,
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "auto",
            margin: "auto",
            rowGap: 5,
            columnGap: 3,
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "80%", md: "76.05%" }, height: "auto" }}>
            <Image
              src={appAnnouncementImageSrc}
              alt="informações sobre o novo aplicativo em breve"
              width={0}
              height={0}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          {/* <Box sx={{ width: { xs: "100%", sm: "80%", md: "23.95%" }, height: "auto" }}>
            <Image
              src={getAssetsPath() + "/img/banner-fale-conosco-funtrab.svg"}
              alt="informações sobre atendimento presencial funtrab"
              width={0}
              height={0}
              style={{ width: "100%", height: "100%" }}
            />
          </Box> */}
        </Box>
      </Container>
    </Box>
  )
}
