"use client"

import { Box, BoxProps } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import { useEffect } from "react"

interface LoadingAnimationProps {
  text?: string
  timeout?: number
  onTimeout?: () => void
  isVisible?: boolean // Adicione uma prop para verificar a visibilidade
}

const containerStyles: BoxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "50vh",
}

/**
 * A loading animation component.
 *
 * @param {LoadingAnimationProps} props - The props for the LoadingAnimation component.
 * @param {string} props.text - The text to display below the loading animation, default='Carregando...'.
 * @param {number} props.timeout - The timeout in milliseconds to call the onTimeout function.
 * @param {() => void} props.onTimeout - The function to call when the timeout is reached, default=5000.
 * @param {boolean} props.isVisible - Whether the component is visible or not.
 * @returns {JSX.Element} The LoadingAnimation component.
 */
export function LoadingAnimation({
  text = "Carregando...",
  timeout = 5000,
  onTimeout = () => {
    window.location.reload()
  },
  isVisible = true, // Valor padrão para visibilidade
}: LoadingAnimationProps): JSX.Element | null {
  useEffect(() => {
    if (!isVisible) return
    const timer = setTimeout(onTimeout, timeout)
    return () => clearTimeout(timer)
  }, [timeout, onTimeout, isVisible])

  if (!isVisible) return null

  return (
    <Box sx={{ ...containerStyles }} role="alert" aria-live="assertive">
      <CircularProgress
        variant="indeterminate"
        color="secondary"
        size={100}
        thickness={4}
        aria-label="Loading"
        sx={{
          color: "#1978CF",
        }}
      />
      <Typography
        variant="body2"
        component="span"
        sx={{
          marginTop: 2,
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}
