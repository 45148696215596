"use client"

import { LoadingAnimation } from "@/components/ui/loading/LoadingAnimation"
import AnnouncementSection from "@/containers/home-page/AnnouncementSection"
import CardsSection from "@/containers/home-page/CardsSection"
// import EmploymentSection from "@/containers/home-page/EmploymentSection";
import Footer from "@/containers/home-page/Footer"
import MostPopularJobsSection from "@/containers/home-page/MostPopularJobsSection"
import WelcomeSection from "@/containers/home-page/WelcomeSection"
import BaseHeader from "@/containers/home-page/header/BaseHeader"
import { useAuth } from "@/hooks/useAuth"
import { useProfiles } from "@/hooks/useProfiles"
import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"
import { getDefaultPanelOfHighestWeightedRole } from "@/utils/profileUtils"
import { Container } from "@mui/material"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { Suspense, useCallback, useEffect } from "react"

function Home() {
  const searchParams = useSearchParams()
  const refresh = searchParams.get("refresh")
  const redirect = searchParams.get("redirect")
  const isLoggedIn = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const { profiles, isLoadingProfiles, isErrorProfiles } = useProfiles()
  const { errorMessage } = useMessageSnackbarStore()

  const handleRedirect = useCallback(
    (url: string) => {
      if (pathname !== url) {
        router.push(url)
      }
    },
    [pathname, router],
  )

  useEffect(() => {
    if (typeof window !== "undefined" && refresh) {
      const url = new URL(window.location.href)
      url.searchParams.delete("refresh")
      window.location.replace(url.toString())
    }
  }, [refresh])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    if (redirect) {
      handleRedirect(redirect)
      return
    }

    // Caso o usuário esteja logado, mas não há perfis ainda carregados
    if (!isLoadingProfiles && !isErrorProfiles) {
      if (!profiles || profiles.length === 0) {
        errorMessage("Nenhum perfil disponível")
        return
      }

      const highestWeightedRolePanel = getDefaultPanelOfHighestWeightedRole(profiles)

      if (!highestWeightedRolePanel) {
        errorMessage("Nenhum painel padrão disponível")
        return
      }

      handleRedirect(highestWeightedRolePanel)
    }
  }, [
    redirect,
    isLoggedIn,
    handleRedirect,
    profiles,
    isLoadingProfiles,
    isErrorProfiles,
    errorMessage,
  ])

  return (
    <>
      <BaseHeader isHome={true} profile={profiles} />
      <Container>
        <WelcomeSection />
        {/* <EmploymentSection />*/}
        <CardsSection />
        <MostPopularJobsSection />
      </Container>
      <AnnouncementSection />
      <Footer />
    </>
  )
}

export default function HomePage() {
  return (
    <Suspense fallback={<LoadingAnimation text="Carregando a página inicial..." />}>
      <Home />
    </Suspense>
  )
}
